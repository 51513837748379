import React from 'react';

const ExternalWebsite = () => {
  const url = 'http://54.65.14.188/'; // 埋め込みたいウェブサイトのURLを指定

  return (
    <div>
      <iframe
        title="external-website"
        src={url}
        width="100%"
        height="600px"
        frameBorder="0"
        style={{border: '1px solid #ccc'}}
      ></iframe>
    </div>
  );
};

export default ExternalWebsite;
