import React from 'react';
import AWS from "../assets/aws.png";

const AWSDiagram = () => {
  return (
    <div>
    <h1 className="profile-title">このサイトはAWSで構築しています。</h1>
      <ul className="profile-list">
        <li>
            <strong>工夫している所</strong>
            <p>EC2上にDockerを立てて、AWS依存になりにくいように設計</p>
            <p>Blog機能をwordpressにして、開発期間を短縮（時間がなかっただけ）</p>
            <p>EC2へのログインセッション履歴をcloudtrailで検知、Lambdaで整形</p>
        </li>
      </ul>
    <img src={AWS} alt="Python" />
    </div>
  );
};

export default AWSDiagram;

