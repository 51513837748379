import React from 'react';
import './Profile.css';

const Profile = () => {
  return (
    <div className="profile">
      <h1 className="Profile-title">Shota Nyumura</h1>
      <h1 className="profile-title">言語・ツール</h1>
      <ul className="profile-list">
        <li>
            <strong>業務</strong>
            <p>AWS、Terraform、Ansible</p>
        </li>
        <li>
            <strong>学習中</strong>
            <p>Python、Node.js、React、Typescript</p>
        </li>
        <li>
            <strong>学習ツール</strong>
            <p>Udemy、Qiita、ChatGPT、etc</p>
        </li>
      </ul>
      <h1 className="profile-title">経歴</h1>
      <ul className="profile-list">
        <li>
          <strong>2009～2013</strong>
          <p>東京工科大学卒業 コンピュータサイエンス学部</p>
        </li>
        <li>
          <strong>2013~2018</strong>
          <p>ドコモ・テクノロジ株式会社</p>
        </li>
        <li>
          <strong>2023~</strong>
          <p>株式会社リベラルセンス</p>
        </li>
      </ul>

      <h1 className="profile-title">表彰</h1>
      <ul className="profile-list">
        <li>
          <strong>2014.12.26</strong>
          <p>コスト削減を実現する為のシステム更改提案 事業部長表彰</p>
        </li>
        <li>
          <strong>2015.12.28</strong>
          <p>保守運用コスト削減提案 事業部長表彰</p>
        </li>
        <li>
          <strong>2016.3.10</strong>
          <p>新システムで耐災害性を低コストで実現した功績 本社開発部長表彰</p>
        </li>
        <li>
          <strong>2016.10.3</strong>
          <p>新システム構築の功績 社長表彰</p>
        </li>
      </ul>

      <h1 className="profile-title">論文</h1>
      <ul className="profile-list">
        <li>
          電子情報通信学会 大規模災害時におけるIP系ネットワークオペレーションサポートシステムの信頼性向上について
        </li>
      </ul>

      <h1 className="profile-title">資格</h1>
      <ul className="profile-list">
        <li>AWS Certified Cloud Practitioner</li>
      </ul>
    </div>
  );
};

export default Profile;