import React, { useState, useEffect } from "react";

const DefaultContent = () => {
  const [lettersToShow1, setLettersToShow1] = useState(0);
  const [lettersToShow2, setLettersToShow2] = useState(0);
  const welcomeText1 = "Wellcome";
  const welcomeText2 = "sanchan dev";

  useEffect(() => {
    const interval1 = setInterval(() => {
      setLettersToShow1((prev) => prev + 1);
      if (lettersToShow1 >= welcomeText1.length) {
        clearInterval(interval1);
      }
    }, 250);

    return () => {
      clearInterval(interval1);
    };
  }, [lettersToShow1]);

  useEffect(() => {
    if (lettersToShow1 >= welcomeText1.length) {
      const interval2 = setInterval(() => {
        setLettersToShow2((prev) => prev + 1);
        if (lettersToShow2 >= welcomeText2.length) {
          clearInterval(interval2);
        }
      }, 250);

      return () => {
        clearInterval(interval2);
      };
    }
  }, [lettersToShow1, lettersToShow2]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <h1
        style={{
          fontSize: "250px",
          fontFamily: "Roboto",
        }}
      >
        {welcomeText1.slice(0, lettersToShow1)}
      </h1>
    </div>
  );
};

export default DefaultContent;
