import React from 'react';
import styles from './StylishLinks.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faAmazon } from '@fortawesome/free-brands-svg-icons';
import { ReactComponent as QiitaLogo} from '../assets/qiita.svg';


const Link = () => {
  return (
    <div className={styles.container}>
      <a href="https://qiita.com/rio_ite" className={styles.link} target="_blank" rel="noopener noreferrer">
        <QiitaLogo className={styles.icon} />
        <span>Qiita</span>
      </a>
      <a href="https://github.com/sannchan" className={styles.link} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGithub} className={styles.icon} />
        <span>GitHub</span>
      </a>
      <a href="https://www.amazon.jp/hz/wishlist/ls/3IYB116PZDVAU?ref_=wl_share" className={styles.link} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faAmazon} className={styles.icon} />
        <span>Amazon</span>
      </a>
    </div>
  );
};

export default Link;

