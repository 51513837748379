import React from 'react';

const ExternalWebsite = () => {
  const url = '/javascript/index.html'; // 埋め込みたいウェブサイトのURLを指定

  return (
    <div>
    <h1 className="profile-title">シュタインズゲート風の時計</h1>
      <ul className="profile-list">
        <li>
            <strong>工夫している所</strong>
            <p>大学生の時に作成したので何も覚えてませんが、久しぶりにみたらスパゲッティコードでした。なぜ動いてるんでしょうか</p>
        </li>
      </ul>
      <iframe
        title="external-website"
        src={url}
        width="100%"
        height="600px"
        frameBorder="0"
        style={{border: '1px solid #ccc'}}
      ></iframe>
    </div>
  );
};

export default ExternalWebsite;
