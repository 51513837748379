import React from 'react';

const YouTubeVideo = () => {
  const videoId = '1un08ireLBM'; // 埋め込みたいYouTube動画のIDを指定

  return (
    <div className="youtube-video">
    <h1 className="profile-title">Pythonで時計とメモと翻訳ができるAPPを作りました。</h1>
      <ul className="profile-list">
        <li>
            <strong>工夫している所</strong>
            <p>標準GUIライブラリのtkinterを使わずにPyQt6で高速なGUI表示を実現</p>
        </li>
      </ul>
      <iframe
        title="youtube-video"
        width="1024"
        height="720"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeVideo;
