import React from "react";
import { Link } from "react-router-dom";
import AWSLogo from "../assets/aws-logo.png";
import PythonLogo from "../assets/python-logo.png";
import JavaScriptLogo from "../assets/javascript-logo.png";
import "./Portfolio.css";


const Portfolio = () => {
  return (
    <div className="portfolio-container">
      <Link to="/aws">
        <img src={AWSLogo} alt="AWS" />
      </Link>
      <Link to="/python">
        <img src={PythonLogo} alt="Python" />
      </Link>
      <Link to="/js">
        <img src={JavaScriptLogo} alt="JavaScript" />
      </Link>
    </div>
  );
};
export default Portfolio;