import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, Route, Routes,useLocation } from "react-router-dom";
import "./App.css";
import OpeningText from "./components/OpeningText";
import Profile from "./components/Profile";
import Portfolio from "./components/Portfolio";
import AWSContent from "./components/AWSContent"
import PythonContent from "./components/PythonContent"
import JSContent from "./components/JSContent"
import Blog from "./components/Blog"
import Link1 from "./components/Link1"

const Opening = () => { 
  const [opacity, setOpacity] = useState(0); 
  const [isVisible, setIsVisible] = useState(true); 
  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 0);

    setTimeout(() => {
      setOpacity(0);
      setTimeout(() => { 
        setIsVisible(false);
      }, 1800); 
    }, 1800);
  }, []);

  if (!isVisible) { 
    return null; 
  } 

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
        opacity: opacity,
        transition: "opacity 2s",
      }}
    >
      <img
        src="/da.png"
        alt="ロゴ"
        style={{
          width: "25%",
          height: "auto",
        }}
      />
    </div>
  );
};


const ContentBar = () => {
  return (
    <div className="content-bar">
      <Link className="content-bar-button" to="/Profile">
        Profile
      </Link>
      <Link className="content-bar-button" to="/Portfolio">
        Portfolio
      </Link>
      <Link className="content-bar-button" to="/Blog">
        Blog
      </Link>
      <Link className="content-bar-button" to="/Link1">
        Link
      </Link>
    </div>
  );
};


const Content = () => {
  const location = useLocation();
  const [showOpening, setShowOpening] = useState(false);
  const [showContentBar, setShowContentBar] = useState(false); 

  useEffect(() => {
    if (location.pathname === "/") {
      setShowOpening(true);
      setTimeout(() => {
        setShowOpening(false);
        setShowContentBar(true);
      }, 4000);
    } else {
      setShowContentBar(true);
    }
  }, [location.pathname]);

  return (
    <>
      {showOpening && <Opening />}
      {showContentBar && <ContentBar />}
      <div className="content-display-area">
        <Routes>
          <Route path="/" element={!showOpening ? <OpeningText /> : null} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          <Route path="/aws" element={<AWSContent />} />
          <Route path="/python" element={<PythonContent />} />
          <Route path="/js" element={<JSContent />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Link1" element={<Link1 />} />
        </Routes>
      </div>
    </>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <Content />
      </div>
    </Router>
  );
}

export default App;





